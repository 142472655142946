<template>
  <v-avatar color="#BDBDBD" :size="size">
    <span class="white--text" :class="{ headline: !size || size > 30 }">
      {{ avatarText }}
    </span>
  </v-avatar>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    size: String,
  },
  computed: {
    avatarText() {
      let name = this.$pocketbase.authStore.model.profile.name
      return name &&
        name !== "" &&
        name.length >= 1
        ? name.charAt(0)
        : "!";
    },
  },
};
</script>

<style scoped></style>
