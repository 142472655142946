import Vue from 'vue'
import VueRouter from 'vue-router'
import Navbar from "@/components/Navbar";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'registrations',
    components: {
      default: () => import(/* webpackChunkName: "registrations" */ '../views/RegistrationsView.vue'),
      navbar: Navbar
    }
  },
  {
    path: '/registrations/create',
    name: 'registrations-create',
    components: {
      default: () => import(/* webpackChunkName: "registrations" */ '../views/CreateRegistrationView.vue'),
      navbar: Navbar
    }
  },
  {
    path: '/registration/:registration_form_id/details',
    name: 'registration-details',
    components: {
      default: () => import(/* webpackChunkName: "registrations" */ '../views/RegistrationDetailsView.vue'),
      navbar: Navbar
    }
  },
  {
    path: '/registration/:registration_form_id',
    name: 'registration',
    components: {
      default: () => import(/* webpackChunkName: "registrations" */ '../views/RegistrationView.vue'),
    }
  },
  {
    path: '/registration/:registration_id/edit',
    name: 'registration-edit',
    components: {
      default: () => import(/* webpackChunkName: "registrations" */ '../views/RegistrationEditView.vue'),
      navbar: Navbar
    }
  },
  {
    path: '/registration-finished',
    name: 'registration-finished',
    components: {
      default: () => import(/* webpackChunkName: "registrations" */ '../views/RegistrationFinishedView.vue'),
    },
    props: true
  },
  {
    path: '/registrations-own',
    name: 'registrations-own',
    components: {
      default: () => import(/* webpackChunkName: "registrations-own" */ '../views/RegistrationsOwnView.vue'),
      navbar: Navbar
    }
  },
  {
    path: '/login',
    name: 'auth-login',
    components: {
      default: () => import(/* webpackChunkName: "auth" */ '../views/AuthLoginView.vue')
    }
  },
  {
    path: '/registration',
    name: 'auth-registration',
    components: {
      default: () => import(/* webpackChunkName: "auth" */ '../views/AuthRegistrationView.vue')
    }
  },
]

const router = new VueRouter({
  routes
})

const routeGuard = async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page>
  const publicPages = ["auth-login", "auth-registration", "registration", "registration-finished"];
  const authRequired = !publicPages.includes(to.name);
  let loggedIn = router.app.$pocketbase.authStore.isValid;
  if (authRequired && !loggedIn) {
    return next("/login");
  }
  next();
};

router.beforeEach(routeGuard);

export default router
