<template>
  <nav>
    <v-app-bar
        dense
        text
        app
        clipped-left
        class="primary white--text"
    >
      <v-app-bar-nav-icon
          @click="mini = !mini"
          class="white--text"
      />
      <v-img
          src="../assets/logo.svg"
          aspect-ratio="1"
          max-width="30"
          contain
          class="mx-md-3 mr-2"
      />
      <v-toolbar-title>
        <span class="title font-weight-bold">Scout</span>
        <span class="title font-weight-light">Tools</span>
      </v-toolbar-title>
      <v-spacer />
      <v-menu
          v-model="avatarMenu"
          bottom
          :offset-y="true"
          max-width="320"
          :close-on-content-click="false"
      >
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom :disabled="isMobile">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn
                  v-on="{ ...onMenu, ...onTooltip }"
                  icon
                  class="mr-0 mr-md-1"
                  height="40"
                  width="40"
              >
                <avatar size="40" />
              </v-btn>
            </template>
            <div>
              <div>Account Information</div>
              <div class="font-weight-bold">
                {{$pocketbase.authStore.model.profile.name}}
              </div>
              <div class="font-weight-bold">{{$pocketbase.authStore.model.email}}</div>
            </div>
          </v-tooltip>
        </template>

        <v-card width="350">
          <div>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <avatar size="40" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{$pocketbase.authStore.model.profile.name}}
                  </v-list-item-title>
                  <v-list-item-subtitle
                  >
                    {{$pocketbase.authStore.model.email}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn text color="primary" @click="logout()">
              <span>Abmelden</span>
              <v-icon right>mdi-logout</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
        app
        clipped
        :permanent="!isMobile && mini"
        :mini-variant="!isMobile && !mini"
        :expand-on-hover="!isMobile && !mini"
        :temporary="isMobile"
    >
      <v-layout column fill-height>
        <v-list dense nav>
          <v-list-item class="hidden-sm-and-up">
            <v-list-item-action>
              <v-img src="../assets/logo.svg" aspect-ratio="1" contain />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="title">
                <span class="title font-weight-bold">Scout</span>
                <span class="title font-weight-light">tools</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="hidden-sm-and-up" />

          <v-list-item to="/">
            <v-list-item-action>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Anmeldungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/registrations-own">
            <v-list-item-action>
              <v-icon>mdi-calendar-check</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Meine Anmeldungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import Avatar from "@/components/Avatar";
export default {
  name: 'Navbar',
  components: {Avatar},
  data() {
    return {
      mini: true,
      avatarMenu: false,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    logout() {
      this.$router.push("/login")
      this.$pocketbase.authStore.clear()
    }
  },
  mounted() {
    console.log(this.$pocketbase.authStore.model.profile.name)
  }
}
</script>

<style scoped>

</style>
